import '../style/style.css'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteLocation, updateLocationName } from '../state/lib/actions'
import Images from '../assets/images/images'
import Element from '../style/screens/location_screen_styles'
import { FaPencilAlt } from 'react-icons/fa'

const LocationScreen = ({navigate}) => {
  const dispatch = useDispatch();
  const locations = useSelector((state) => state.app.locations);
  const currentLocation = useSelector((state) => state.app.currentLocation);
  const userId = useSelector((state) => state.app.user.id);
  const [locationName, setLocationName] = useState('');
  const [locationAddress, setLocationAddress] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (currentLocation) {
      setLocationName(currentLocation.name || '');
      setLocationAddress(currentLocation.street_address1 || '');
    }
  }, [currentLocation]);

  const handleSaveChanges = async () => {
    if (!currentLocation?.id) return;
    
    try {
      dispatch(updateLocationName({
        locationId: currentLocation.id,
        name: locationName,
        address: locationAddress,
        userId: userId
      }));
      
      await new Promise(resolve => setTimeout(resolve, 500));
      
      setIsEditing(false);
      navigate('dashboard');
    } catch (error) {
      console.error('Error updating location:', error);
    }
  };

  const handleDelete = () => {
    if (!currentLocation?.id) return;
    dispatch(deleteLocation(currentLocation.id));
    navigate('dashboard');
  };

  return (
    <Element>
      <Element.header>
        <Element.header.logo src={Images.rosieLogoColor}/>
        <Element.header.right>
          <Element.header.home src={Images.iconRoundColorWhiteBG}/>
          <Element.header.profile src={Images.avatar}/>
        </Element.header.right>
      </Element.header>
      <Element.form>
        <Element.locationName>
          {isEditing ? (
            <div style={{ width: '100%' }}>
              <Element.input
                type="text"
                value={locationName}
                onChange={(e) => setLocationName(e.target.value)}
                placeholder="Location Name"
                autoFocus
              />
              <Element.input
                type="text"
                value={locationAddress}
                onChange={(e) => setLocationAddress(e.target.value)}
                placeholder="Address"
                style={{ marginTop: '10px', width: '100%' }}
              />
            </div>
          ) : (
            <>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>{currentLocation?.name}</div>
                <Element.locationAddress>
                  {currentLocation?.street_address1 || 'No address provided'}
                </Element.locationAddress>
              </div>
              <Element.picEdit onClick={() => setIsEditing(true)}>
                <FaPencilAlt color="white" size={14} />
              </Element.picEdit>
            </>
          )}
        </Element.locationName>
        <Element.btnDark onClick={handleSaveChanges}>
          Save Changes
        </Element.btnDark>
        <Element.btnLight onClick={handleDelete}>
          Delete Location
        </Element.btnLight>
      </Element.form>
    </Element>
  );
};

export default LocationScreen;
