import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FaChevronDown, FaChevronRight, FaCog } from 'react-icons/fa'
import { getIcon } from '../constants/icons'
import Header from '../components/Header'
import Modal from 'react-modal'
import Images from '../assets/images/images'
import Element from '../style/screens/dashboard_screen_styles'
import '../style/style.css'
import {action} from "../state/lib/actions";
import {GET_ASSET} from "../state/lib/action_types";
import { addLocation, setCurrentLocation } from '../state/lib/actions';

const DashboardScreen = ({ navigate }) => {
  const user = useSelector((state) => state.app.user)
  const dispatch = useDispatch()
  const locations = useSelector((state) => state.app.locations)
  const [selectedTab, setSelectedTab] = useState('spaces')
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const currentLocation = useSelector((state) => state.app.currentLocation)

  const AssetListItem = ({id, title, subtitle, icon, thumbnail}) => {
    const Icon = getIcon(icon)
    return (
      <Element.assetListItem onClick={() => getAsset(id)}>
        <Element.assetListItem.body>
          <Element.assetImage src={thumbnail}/>
          <Element.assetListItem.content>
            <Element.assetListItem.title>{title}</Element.assetListItem.title>
            <Element.assetListItem.space>
              <Icon size='15px' color='#413E3F'/>
              <Element.assetListItem.subtitle>{subtitle}</Element.assetListItem.subtitle>
            </Element.assetListItem.space>
          </Element.assetListItem.content>
        </Element.assetListItem.body>
        <FaChevronRight/>
      </Element.assetListItem>
    )
  }

  const SpaceTile = ({title, subtitle, icon}) => {
    const Icon = getIcon(icon)
    return (
      <Element.spaceTile>
        <Icon size='24px' color='#57BBF8'/>
        <Element.spaceTile.title>{title}</Element.spaceTile.title>
        <Element.spaceTile.subtitle>{subtitle}</Element.spaceTile.subtitle>
      </Element.spaceTile>
    )
  }

  const getAsset = (id) => {
    dispatch(action({type: GET_ASSET, data: {id}}))
    setTimeout(() => navigate('asset'), 500)
  }

  const selectLocation = (location) => {
    dispatch(setCurrentLocation(location))
    setTimeout(() => setModalIsOpen(false), 300)
  }

  useEffect(() => {
    if (locations.length > 0 && !currentLocation) {
      const savedLocationId = localStorage.getItem('currentLocationId');
      if (savedLocationId) {
        const savedLocation = locations.find(loc => loc.id === parseInt(savedLocationId));
        if (savedLocation) {
          dispatch(setCurrentLocation(savedLocation));
          return;
        }
      }
      dispatch(setCurrentLocation(locations[0]));
    }
  }, [locations, currentLocation]);

  useEffect(() => {
    if (currentLocation?.id) {
      localStorage.setItem('currentLocationId', currentLocation.id);
    }
  }, [currentLocation]);

  const handleNewLocationClick = () => {
    console.log("Button clicked! Navigating to new location.");
    navigate('new_location');
  };

  const handleAddLocation = async (newLocationData) => {
    await dispatch(addLocation(newLocationData));
    setModalIsOpen(true);
  };

  const handleLocationSettings = (location) => {
    dispatch(setCurrentLocation(location));
    navigate('location');
  };

  const handleLocationSelect = (location) => {
    dispatch(setCurrentLocation(location));
    navigate('location');
  };

  return (
    <Element>
      <Header navigate={navigate} screen='dashboard'/>
      <Element.form>
        <Element.title>Your inventory at</Element.title>
        <Element.locationSelect>
          <Element.currentLocation onClick={() => setModalIsOpen(true)}>
            {currentLocation?.name}
          </Element.currentLocation>
          <FaChevronDown className="d-select-chevron"/>
        </Element.locationSelect>

        <Element.tabs>
          <Element.tab>
            {selectedTab === 'spaces' ?
              (<Element.tabLink>Spaces</Element.tabLink>) :
              (<Element.tabInactive href='#' onClick={() => setSelectedTab('spaces')}>Spaces</Element.tabInactive>)
            }
          </Element.tab>
          <Element.tab>
            {selectedTab === 'assets' ?
              (<Element.tabLink>Assets</Element.tabLink>) :
              (<Element.tabInactive href='#' onClick={() => setSelectedTab('assets')}>Assets</Element.tabInactive>)
            }
          </Element.tab>
        </Element.tabs>

        <Element.tabContent>
          {selectedTab === 'spaces' ? (
            <Element.grid>
              {locations?.find((l) => l.id == currentLocation?.id)?.spaces?.map(space => {
                const assetCount = space.assets?.length || 0;
                const subtitle = assetCount + ' ' + (assetCount !== 1 ? 'items' : 'item')
                return <SpaceTile
                  key={space.id}
                  title={space.name}
                  subtitle={subtitle}
                  icon={space.type}
                />
              })
              }
            </Element.grid>
          ) : (
            <Element.list>
              {locations.find((l) => l.id == currentLocation?.id)?.spaces.map(space =>
                space.assets?.map((asset => {
                  return <AssetListItem
                    id={asset.id}
                    key={asset.id}
                    title={asset.name}
                    subtitle={space.name}
                    icon={space.type}
                    thumbnail={asset.thumbnail}
                  />
                }))
              )
              }
            </Element.list>
          )
          }
        </Element.tabContent>

        <div>Version 0.5.5</div>
        <div>Last Updated: Thu Jan 02, 4:33 pm EDT</div>
      </Element.form>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel='Image Data'
        overlayClassName='i-modal-overlay'
        className='d-modal'
        ariaHideApp={false}
      >
        <Element.modalContent>
          <Element.modalHeader>
            <Element.modalLogo src={Images.rosieLogoColor}/>
            <Element.modalHeaderRight>
              <Element.modalProfile src={Images.closeBtn} onClick={() => setModalIsOpen(false)}/>
            </Element.modalHeaderRight>
          </Element.modalHeader>
          <Element.form>
            {locations.map((location) => {
              return(
                (currentLocation?.id === location.id) ?
                  (<Element.listingSelected key={location.id}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                      <span onClick={() => selectLocation(location)}>{location.name}</span>
                      <FaCog 
                        onClick={(e) => {
                          e.stopPropagation();
                          handleLocationSettings(location);
                        }}
                        style={{ cursor: 'pointer', marginLeft: '10px' }}
                        size={16}
                      />
                    </div>
                  </Element.listingSelected>) :
                  (<Element.listing key={location.id} onClick={() => selectLocation(location)}>
                    {location.name}
                  </Element.listing>
                )
              )
            })}
            <Element.listing key="new-location">
              <Element.button onClick={handleNewLocationClick}>
                New Location
              </Element.button>
            </Element.listing>
          </Element.form>
        </Element.modalContent>
      </Modal>
    </Element>
  )
}

export default DashboardScreen
