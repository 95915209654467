import React, { useState } from 'react';
import Element from '../style/screens/new_location_screen_styles';
import { useDispatch, useSelector } from 'react-redux';
import { addLocation, action } from '../state/lib/actions';
import { GET_FULL_USER_LOCATIONS } from "../state/lib/action_types";

const NewLocationScreen = ({ navigate }) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.app.user.id);
  const locations = useSelector((state) => state.app.locations);
  const [locationName, setLocationName] = useState('');
  const [address, setAddress] = useState('');
  const [image, setImage] = useState(null);
  const [error, setError] = useState('');

  const handleLocationNameChange = (e) => {
    const value = e.target.value;
    setLocationName(value);
    // Real-time validation for location name
    if (value.length > 80) {
      setError('Location name cannot exceed 80 characters.');
    } else {
      setError('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form inputs
    if (!locationName.trim()) {
      setError('Location name is required.');
      return;
    }

    if (!address.trim()) {
      setError('Address is required.');
      return;
    }

    if (locationName.length > 80) {
      setError('Location name cannot exceed 80 characters.');
      return;
    }

    if (address.length > 100) {
      setError('Address cannot exceed 100 characters.');
      return;
    }

    if (!locations) {
      console.log("No locations found");
      return;
    }

    // Dispatch the action to add a new location
    dispatch(addLocation({ 
      user_id: userId, 
      name: locationName.trim(), 
      street_address1: address.trim() 
    }));

    // Reset form and navigate
    dispatch(action({type: GET_FULL_USER_LOCATIONS, data: {userId: userId}}))
    navigate('dashboard');
  };

  const handleImageCapture = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Element>
      <Element.form onSubmit={handleSubmit}>
        <Element.title>Add New Location</Element.title>
        
        {error && <Element.error>{error}</Element.error>}

        <Element.fileInputContainer>
          <Element.fileInput
            type="file"
            accept="image/*"
            capture="camera"
            onChange={handleImageCapture}
          />
        </Element.fileInputContainer>

        {image && <Element.imagePreview src={image} alt="Captured" />}

        <label htmlFor="locationName">Location Name</label>
        <Element.input
          id="locationName"
          type="text"
          placeholder="Enter location name"
          value={locationName}
          onChange={handleLocationNameChange}
          maxLength={80}
          required
        />

        <label htmlFor="address">Address</label>
        <Element.input
          id="address"
          type="text"
          placeholder="Enter address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          maxLength={100}
          required
        />

        <Element.button type="submit">Add Location</Element.button>
        <Element.button type="button" onClick={() => navigate('dashboard')}>
          Cancel
        </Element.button>
      </Element.form>
    </Element>
  );
};

export default NewLocationScreen;
