import styled from 'styled-components'
import { Colors } from '../../constants/colors'

const Element = styled.div`
  min-height: 100vh;
  padding: 20px;
 `
Element.header = styled.div`
  display: flex;
  justify-content: space-between;
`
Element.header.logo = styled.img`
`
Element.header.right = styled.div`
  display: flex;
  gap: 10px;
`
Element.header.home = styled.img`
  height: 40px;
  width: 40px;
`
Element.header.profile = styled.img`
  height: 38px;
  width: 38px;
`
Element.title = styled.div`
  font-size: 36px;
  line-height: 39px;
  text-align: center;
  color: #221F20;
`
Element.errorText = styled.div`
  color: #ff0000;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 8px;
`
Element.subtitle = styled.div`
  margin-top: 5px;
  margin-bottom: 20px;
  margin-left: 3px;
  margin-right: 3px;
  color: #413E3F;
  text-align: center;
`
Element.form = styled.div`
  margin-top: 40px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`
Element.photo = styled.div`
  position: relative;
  margin-top: 20px;
`
Element.pic = styled.img`
`
Element.picEdit = styled.img`
  position: absolute;
  margin-top: -60px;
  margin-left: 270px;
`
Element.mdSelect = styled.select`
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border-color: rgb(59 130 246);
  font-size: 16px;
`

Element.textInput = styled.input`
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border-color: rgb(59 130 246);
  padding: 3px 5px 3px 5px;
  font-size: 16px;  
`

Element.textArea = styled.textarea`
  width: 100%;
  border-radius: 5px;
  border-color: rgb(59 130 246);
  padding: 3px 5px 3px 5px;
  font-size: 16px;  
`

Element.mdOption = styled.option`
width: 100%; 
`

Element.btnDark = styled.button`
  background: #483EE7;
  border-radius: 8px;
  color: white;
  font-weight: 700;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 10px;
  width: 335px;
`
Element.btnContent = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
`
Element.mdRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`
Element.locationBtn = styled.img`
  width: 100%;
`
Element.modalContent = styled.div`
 padding: 20px;
 width: 340px;
`
Element.grid = styled.div`
  margin-top: 8px;
  display: grid;
  grid-template-columns: 173px 173px;
`

Element.spaceTile = styled.div`
  height: 78px;
  width: 126px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 25px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 3px;
  border-radius: 8px;
  background-color: white;
  margin-bottom: 8px;
  box-shadow: 0 1px 2px 0 rgba(100, 100, 100, 0.3);
  border: ${props => props.selected ? "solid #483EE7" : 'none'};
`
Element.spaceTile.title = styled.div`
  color: #221F20;
  font-size: 16px;
  font-weight: 700;
  margin-top: 12px;
  font-weight: ${props => props.selected ? 700 : 200};
`
Element.spaceTile.subtitle = styled.div`
  color: #221F20;
  font-size: 10px;
  font-weight: ${props => props.selected ? 600 : 400};
`

export default Element