import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {action} from '../state/lib/actions'
import {CREATE_ASSET, UPDATE_ASSET} from '../state/lib/action_types'
import Images from '../assets/images/images'
import Element from '../style/screens/asset_screen_styles'
import {State} from '../state/state'
import Header from '../components/Header'
import '../style/style.css'
import {getIcon} from "../constants/icons";

const AssetScreen = ({navigate}) => {

  const dispatch = useDispatch()
  const user = useSelector((state) => state.app.user)
  const currentAsset = useSelector((state) => state.app.currentAsset)
  // Update the initial location state setup
  const locations = useSelector((state) => state.app.locations)
  const defaultLocation = locations[0]
  const defaultSpace =  defaultLocation?.spaces[0]
  const [currentLocationId, setCurrentLocationId] = useState(defaultLocation?.id)
  const [currentLocation, setCurrentLocation] = useState(locations.find((l) => l.id == currentLocationId))
  const [selectedSpaceId, setSelectedSpaceId] = useState(defaultSpace?.id)

  //Add error state for name
  const [nameError, setNameError] = useState('')
  // Add new state for purchase date error 
  const [purchaseDateError, setPurchaseDateError] = useState('')
  // Add new state for model info error 
  const [modelInfoError, setModelInfoError] = useState('')
  // Add new state for serial number error 
  const [serialNumberError, setSerialNumberError] = useState('')
  // Add new state for description error 
  const [descriptionError, setDescriptionError] = useState('')
  const [locationError, setLocationError] = useState('')
  const [spaceError, setSpaceError] = useState('')




  const formatDate = (date) => {
    const d = date instanceof Date ? date : new Date(date);
    return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
  }

  const [fields, setFields] = useState({
    name: '',
    purchaseDate: formatDate(new Date()),
    modelInfo: '',
    serialNumber: '',
    description: '',
  })


      useEffect(() => {
        // Determine initial location and space
        const initialLocation = locations.find((l) => l.id === currentAsset?.locationId) || locations[0];
        const defaultSpace = initialLocation?.spaces?.[0];

        setCurrentLocationId(initialLocation?.id || null);
        setCurrentLocation(initialLocation);
        setSelectedSpaceId(currentAsset?.spaceId || defaultSpace?.id || null);

        // Populate the fields state from currentAsset
        if (currentAsset) {
          setFields({
            name: currentAsset.name || '', // Use existing value or empty string
            purchaseDate: currentAsset.purchaseDate ? formatDate(currentAsset.purchaseDate) : formatDate(new Date()), // Format the purchase date
            modelInfo: currentAsset.modelInfo || '',
            serialNumber: currentAsset.serialNumber || '',
            description: currentAsset.description || '',
          });
        } else {
          // Reset fields if no currentAsset
          setFields({
            name: '',
            purchaseDate: formatDate(new Date()),
            modelInfo: '',
            serialNumber: '',
            description: '',
          });
        }
      }, [currentAsset, locations]);


  const createAsset = () => {
    //check for validation
    if (nameError || !fields.name || 
        purchaseDateError || !fields.purchaseDate || 
        modelInfoError || serialNumberError || 
        descriptionError || !currentLocation ||
        !selectedSpaceId) {  // Add space check
      setNameError(fields.name ? nameError : 'Name is required')
      setPurchaseDateError(fields.purchaseDate ? purchaseDateError : 'Purchase date is required')
      setLocationError(!currentLocation ? 'Location is required' : '')
      setSpaceError(!selectedSpaceId ? 'Space is required' : '')
      return
    }
    const data = {
      userId: user?.id,
      purchaseDate: fields.purchaseDate,
      modelInfo: fields.modelInfo || '',          //convert undefined to empty string
      name: fields.name,
      serialNumber: fields.serialNumber || '',  //convert undefined to empty string
      description: fields.description || '',  //convert undefined to empty string
      thumbnail: State.assetThumbnailUrl,
      locationId: currentLocation?.id,
      spaceId: selectedSpaceId,
    }
    dispatch(action({type: CREATE_ASSET, data}))
    navigate('dashboard')
  }

  const updateAsset = () => {
    //check for validation
    if (nameError || !fields.name || 
        purchaseDateError || !fields.purchaseDate || 
        modelInfoError || serialNumberError || 
        descriptionError || !currentLocation ||
        !selectedSpaceId) {  // Add space check
      setNameError(fields.name ? nameError : 'Name is required')
      setPurchaseDateError(fields.purchaseDate ? purchaseDateError : 'Purchase date is required')
      setLocationError(!currentLocation ? 'Location is required' : '')
      setSpaceError(!selectedSpaceId ? 'Space is required' : '')
      return
    }
    const data = {
      id: State.matchingAssetId,
      userId: user?.id,
      name: fields?.name,
      purchaseDate: fields?.purchaseDate,
      modelInfo: fields?.modelInfo || '',          //convert undefined to empty string
      serialNumber: fields?.serialNumber || '',   //convert undefined to empty string
      description: fields?.description || '',   //convert undefined to empty string
      thumbnail: State.assetThumbnailUrl,
      locationId: currentLocation?.id,
      spaceId: selectedSpaceId,
    }
    dispatch(action({type: UPDATE_ASSET, data}))
    navigate('dashboard')
  }

  const selectLocation = (id) => {
    setSelectedSpaceId(null)
    setCurrentLocationId(id)
    setCurrentLocation(locations.find((l) => l.id == id ))
  }

  const handleTextInputChange = async (fieldName, text) => {
    const updatedFields = {...fields} //create new object to avoid direct mutation
    updatedFields[fieldName] = text
    await setFields(updatedFields)

      // Existing name validation
    if (fieldName === 'name') {
      if (!text || text.trim() === '') {
        setNameError('Name is required')
      } else if (text.length > 255) {
        setNameError('Name must be less than 255 characters')
      } else {
        setNameError('')
      }
    }

      // Purchase date validation
    if (fieldName === 'purchaseDate') {
      if (!text || text.trim() === '') {
        setPurchaseDateError('Purchase date is required')
      } else if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(text)) {
        setPurchaseDateError('Invalid date format (MM/DD/YYYY)')
      } else {
        const [month, day, year] = text.split('/')
        const inputDate = new Date(year, month - 1, day)
        const today = new Date()
        today.setHours(0, 0, 0, 0) // Reset time to midnight for accurate date comparison
        
        if (inputDate > today) {
          setPurchaseDateError('Purchase date cannot be in the future')
        } else if (inputDate.toString() === 'Invalid Date') {
          setPurchaseDateError('Invalid date')
        } else {
          setPurchaseDateError('')
        }
      }
    }

      // Model info validation
    if (fieldName === 'modelInfo') {
      if (text.length > 80) {
        setModelInfoError('Model info must be less than 80 characters')
      } else if (/[<>{}]/.test(text)) {  // Check for potentially harmful characters
        setModelInfoError('Model info contains invalid characters')
      } else {
        setModelInfoError('')
      }
    }

      // Serial number validation
    if (fieldName === 'serialNumber') {
      if (text.length > 80) {
        setSerialNumberError('Serial number must be less than 80 characters')
      } else {
        setSerialNumberError('')
      }
    }

      // Description validation
    if (fieldName === 'description') {
      if (text.length > 255) {
        setDescriptionError('Description must be less than 255 characters')
      } else {
        setDescriptionError('')
      }
    }
  }

  const SpaceTile = ({title, subtitle, icon, setSelectedSpaceId, selected, id}) => {
    const Icon = getIcon(icon)
    return (
      <Element.spaceTile selected={selected} onClick={() => setSelectedSpaceId(id)}>
        <Icon size='24px' color='#57BBF8'/>
        <Element.spaceTile.title selected={selected}>{title}</Element.spaceTile.title>
        <Element.spaceTile.subtitle selected={selected}>{subtitle}</Element.spaceTile.subtitle>
      </Element.spaceTile>
    )
  }
  
  return (
    <Element>
      <Header navigate={navigate} screen='asset'/>
      <Element.form>
        <Element.title>Add some details to your item</Element.title>
        <Element.subtitle>
          We’ve got you started with some of the information we could gather.
        </Element.subtitle>

        <Element.photo>
          <Element.pic className='item-confirm' src={State.assetThumbnailUrl}/>
          <Element.picEdit src={Images.edit}/>
        </Element.photo>
        
        <Element.textInput
          type="text" 
          placeholder="Name"
          onChange={e => handleTextInputChange('name', e.target.value)}
          value={fields.name}
          style={nameError ? {borderColor: '#ff0000'} : {}}
        />
        {nameError && <Element.errorText>{nameError}</Element.errorText>}
      
        <Element.textInput
          type="text" 
          placeholder="Purchase Date"
          onChange={e => handleTextInputChange('purchaseDate', e.target.value)}
          value={fields.purchaseDate}
          style={purchaseDateError ? {borderColor: '#ff0000'} : {}}
        />
        {purchaseDateError && <Element.errorText>{purchaseDateError}</Element.errorText>}

        <Element.textInput
          type="text" 
          placeholder="Model Information (optional)"
          onChange={e => handleTextInputChange('modelInfo', e.target.value)}
          value={fields.modelInfo}
          style={modelInfoError ? {borderColor: '#ff0000'} : {}}
        />
        {modelInfoError && <Element.errorText>{modelInfoError}</Element.errorText>}

        <Element.textInput
          type="text" 
          placeholder="Serial Number (optional)"
          onChange={e => handleTextInputChange('serialNumber', e.target.value)}
          value={fields.serialNumber}
          style={serialNumberError ? {borderColor: '#ff0000'} : {}}
        />
        {serialNumberError && <Element.errorText>{serialNumberError}</Element.errorText>}

        <Element.textArea
          rows={4}
          type="text" 
          placeholder="Description (optional)"
          onChange={e => handleTextInputChange('description', e.target.value)}
          value={fields.description}
          style={descriptionError ? {borderColor: '#ff0000'} : {}}
        ></Element.textArea>
        {descriptionError && <Element.errorText>{descriptionError}</Element.errorText>}

        <div>Where is this located?</div>
        <Element.mdSelect 
          onChange={(e) => selectLocation(e.target.value)}
          value={currentLocationId || ''}  // Add this to ensure the value is controlled
          style={locationError ? {borderColor: '#ff0000'} : {}}
        >
          {/* <Element.mdOption value="">Select a location</Element.mdOption> */}
          {locations.map((location) => {
            return <Element.mdOption key={location.id} value={location.id}>{location.name}</Element.mdOption>
          })}
        </Element.mdSelect>
        {locationError && <Element.errorText>{locationError}</Element.errorText>}

        <Element.grid>
            {currentLocation?.spaces?.map(space => {
              const assetCount = space.assets.length
              const subtitle = assetCount + ' ' + (assetCount !== 1 ? 'items' : 'item')
              return <SpaceTile
                key={space?.id}
                id={space?.id}
                title={space?.name}
                subtitle={subtitle}
                icon={space?.type}
                selected={selectedSpaceId === space?.id}
                setSelectedSpaceId={setSelectedSpaceId}
              />
            })}
        </Element.grid>
        {spaceError && <Element.errorText>{spaceError}</Element.errorText>}

        {State.matchingAssetId ? (
          <Element.btnDark type='button' onClick={updateAsset}>
            <Element.btnContent>
              <span>Update Asset</span>
            </Element.btnContent>
          </Element.btnDark>
        ) : (
          <Element.btnDark type='button' onClick={createAsset}>
            <Element.btnContent>
              <span>Add to Inventory</span>
            </Element.btnContent>
          </Element.btnDark>
        )
        }

      </Element.form>
    </Element>
  )
}

export default AssetScreen
