import '../style/style.css'
import React, {useEffect} from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import Images from '../assets/images/images'
import Element from '../style/screens/thumb_select_screen_styles'
import "swiper/css";
import {State} from '../state/state'
import { useSelector, useDispatch } from 'react-redux'
import { updateIdentify } from '../state/lib/actions'
/*
   <SwiperSlide><Element.thumb src={Images.itemCheckPhoto}/></SwiperSlide>
 */

const ThumbSelectScreen = ({navigate}) => {
  const dispatch = useDispatch()
  const identify = useSelector(state => state.app.identify)

  useEffect(() => {
    console.log('In thumbnail useEffect - Initial State:', {
      identifyUrl: identify?.imageUrl,
      stateImages: State?.images,
      stateImageUrls: State?.imageUrls
    });
  }, [identify])

  const handleSlideChange = (swiper) => {
    console.log('Current slide index is:', swiper.activeIndex);
    console.log('Available images:', State?.images);
    console.log('Available imagesUrls:', State?.imageUrls);

    if (State?.images[swiper.activeIndex]) {
      State.assetThumbnail = State?.images[swiper.activeIndex];
      State.assetThumbnailUrl = State?.imageUrls[swiper.activeIndex];

      console.log('Before dispatch - thumbnailUrl:', State?.assetThumbnailUrl);
      dispatch(updateIdentify({ imageUrl: State?.assetThumbnailUrl }))
    }
  }

  const saveThumbnail = () => {
    // Add verification log before navigation
    console.log('### In save thumbnail:', {
      thumbnail: State?.assetThumbnail,
      thumbnailUrl: State?.assetThumbnailUrl
    });
    navigate('asset');
  }

  return (
    <Element>
    <Element.header>
        <Element.header.logo src={Images.rosieLogoColor} />
        <Element.header.right>
          <Element.header.home src={Images.homeBlueBtn} />
          <Element.header.profile src={Images.avatar} />
        </Element.header.right>
      </Element.header>

      <Element.form>
        <Element.title>Select a thumbnail for your item</Element.title>
        <Element.subtitle>
          Choose an image to represent this item within your inventory. You can change it later, too.
        </Element.subtitle>
        <Swiper 
          onSlideChange={handleSlideChange}
          onInit={(swiper) => handleSlideChange(swiper)}
        >
          {State.images.map((image, index) => (
            <SwiperSlide key={index}>
              <Element.thumb src={image}/>
            </SwiperSlide>
          ))}
        </Swiper>
        <Element.btnDark type='button' onClick={saveThumbnail}>
          <Element.btnContent>
            <span>Select Photo</span>
          </Element.btnContent>
        </Element.btnDark>
        <Element.btnLight type='button'>
          Choose New Photo
        </Element.btnLight>
      </Element.form>
    </Element>
  )
}

export default ThumbSelectScreen