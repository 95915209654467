import axios from 'axios'
import { API_URL } from '../constants/constants'

class API {
  static async createAsset(payload) {
    const data = await axios.post(`${API_URL}/public/createAsset`, payload).then((result) => result.data)
    console.log ('CREATE ASSET DATA: ' + data)
    return data
  }
  static async init(payload) {
    const data = await axios.get(`${API_URL}/public/init`, payload).then((result) => result.data)
    console.log ('INIT DATA: ' + data)
    return data
  }
  static async getAsset(id) {
    const data = await axios.post(`${API_URL}/public/getAsset`, {id}).then((result) => result.data)
    return data
  }
  static async getFullUserLocations(userId) {
    const data = await axios.post(`${API_URL}/public/getFullUserLocations`, {userId}).then((result) => result.data)
    return data
  }
  static async login(payload) {
    console.log('API login()')
    const data = await axios.post(`${API_URL}/public/login`, payload).then((result) => result.data.result)
    return data
  }
  static async register(payload) {
    console.log('API register(): ', payload)
    const data = await axios.post(`${API_URL}/public/register`, payload).then((result) => result.data.result)
    return data
  }
  static async score(payload) {
    const data = await axios.post(`${API_URL}/public/score`, payload).then((result) => result.data.result)
    console.log ('SCORE DATA: ' + data)
    return data
  }
  static async updateAsset(payload) {
    const data = await axios.put(`${API_URL}/public/updateAsset`, payload).then((result) => result.data.response)
    console.log ('UPDATE ASSET DATA: ' + data)
    return data
  }
  static async uploadImage(payload) {
    const data = await axios.post(`${API_URL}/public/uploadImage`, payload).then((result) => result.data.response)
    console.log ('UPLOAD IMAGE DATA: ' + data)
    return data
  }
  static async addLocation(payload) {
    console.log('API addLocation(): ', payload)
    const data = await axios.post(`${API_URL}/public/addLocation`, payload).then((result) => result.data.result);
    return data;
  }
  static async deleteLocation(payload) {
    console.log('API deleteLocation(): ', payload)
    const data = await axios.put(`${API_URL}/public/deleteLocation`, payload)
      .then((result) => result.data.locations)
    console.log('DELETE LOCATION DATA: ' + data)
    return data
  }
  static async updateLocationName(payload) {
    const { locationId, name, address, userId } = payload;
    console.log('API updateLocationName():', payload);
    
    const data = await axios.put(
      `${API_URL}/public/updateLocationName`, 
      { locationId, name, address, userId }
    ).then((result) => result.data.locations);
    
    console.log('UPDATE LOCATION NAME DATA:', data);
    return data;
  }
}
export default API
